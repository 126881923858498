// 信息列表
<template>
  <v-container
    id="regular-tables"
    tag="section"
    fluid
  >
    <base-material-card
      inline
      :color="formNameColor"
      icon="mdi-clipboard-text"
      :title="`重点对外推介项目库（共${projectTotal}个）`"
      class="mb-0 px-2 px-sm-5 px-md-5 px-lg-5 px-xl-5 py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3 mt-12"
    >
      <v-row :class="isXs?'titleSearch mt-2':'mt-2'">
        <v-col
          cols="4"
          sm="2"
          md="2"
          lg="2"
          xl="2"
          class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
        >
          <v-text-field
            v-model="parentName"
            label="项目名称"
            solo
            dense
            clearable
          />
        </v-col>
        <v-col
          cols="4"
          sm="2"
          md="2"
          lg="2"
          xl="2"
          class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
        >
          <v-select
            v-model="industry"
            clearable
            :items="industrys"
            label="产业类别"
            item-text="name"
            item-value="code"
            solo
            dense
          />
        </v-col>

        <v-col
          cols="4"
          sm="2"
          md="2"
          lg="2"
          xl="2"
          class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
        >
          <v-text-field
            v-model="fillingUser"
            label="提报用户"
            solo
            dense
            clearable
          />
        </v-col>
        <v-col
          cols="4"
          sm="2"
          md="2"
          lg="2"
          xl="2"
          class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3"
        >
          <v-btn
            elevation="1"
            style="max-width:100%;"
            color="blue mt-0"
            @click="getProjectList"
          >
            <v-icon left>
              fa4 fa-search
            </v-icon>
            搜索
          </v-btn>
        </v-col>
        <v-col
          cols="4"
          sm="2"
          md="2"
          lg="2"
          xl="2"
          class="py-1 py-sm-3 py-md-3 py-lg-3 py-xl-3 text-right"
        >
          <v-btn
            elevation="1"
            style="max-width:100%;"
            color="blue mt-0"
            @click="exportTable"
          >
            <v-icon left>
              fa4 fa-external-link
            </v-icon>
            导出
          </v-btn>
        </v-col>
      </v-row>
      <v-simple-table>
        <thead>
          <tr :class="isXs ? 'titles' : ''">
            <th
              style="min-width:54px"
            >
              序号
            </th>
            <th style="min-width:140px">
              名称
            </th>
            <th style="min-width:84px">
              产业类别
            </th>
            <th style="min-width:64px">
              项目审核亮灯
            </th>
            <th style="min-width:64px">
              项目推进亮灯
            </th>
            <th style="min-width:84px">
              推送时间
            </th>
            <th style="min-width:84px">
              提报用户
            </th>
            <!-- <th width="200">
              预警信息
            </th> -->
            <th
              style="min-width:150px"
              class="text-right"
            >
              操作
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(project, index) in projectList"
            :key="index"
            :class="isXs ? 'names' : ''"
          >
            <td>{{ (searchParame.page) * (searchParame.size) + index + 1 }}</td>
            <td>
              <span
                class="indigo--text projectNameLink"
                @click="see(index)"
              >
                {{ project.data.parentName }}
              </span>
              <v-btn
                v-copy="project.data.parentName"
                icon
                small
                dark
                color="#ccc"
              >
                <v-icon
                  x-small
                >
                  fa fa-clipboard
                </v-icon>
              </v-btn>
            </td>
            <td>{{ project.data.industry }}</td>
            <td>
              <v-icon :color="timeColor(project)">
                mdi-lightbulb
              </v-icon>
            </td>
            <td>
              <v-icon :color="timeColor2(project)">
                mdi-lightbulb
              </v-icon>
            </td>
            <td>{{ project.createdAt | dateformat1 }}</td>
            <td>{{ project.data.fillingUser }}</td>
            <!-- <td>
              <v-icon :color="timeColor(project)">
                mdi-lightbulb
              </v-icon> {{ project.data.answerTime ? project.data.answerTime:'---' }}
            </td> -->
            <td class="text-right">
              <v-btn
                class="px-2 ml-1 mt-1"
                min-width="0"
                small
                color="#0D47A1"
                @click="getAdvance(project)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  mdi-file-sync
                </v-icon>
                项目推进情况
              </v-btn>
              <v-btn
                class="px-2 ml-1 mt-1"
                min-width="0"
                small
                color="success"
                @click="see(index)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-eye
                </v-icon>
                查看
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-pagination
        v-model="nowPage"
        :length="pageNum"
        total-visible="10"
        color="indigo darken-4"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        @input="pageChange"
      />
    </base-material-card>
    <div class="py-3" />
    <v-overlay
      :value="overlay"
    >
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <audioBox ref="audioBox" />
    <plan-report ref="PlanReport" />
    <advance-report ref="AdvanceReport" />
  </v-container>
</template>
<script>
  import file from '../../utils/file'
  export default {
    components: {
      audioBox: () => import('../../components/base/Audio'),
      PlanReport: () => import('../../views/report/PlanReport.vue'),
      AdvanceReport: () => import('../report/AdvanceReport.vue'),
    },
    data: () => ({
      dynamicformId: 'cf45fb6b-8c8f-4e1e-b9f8-1b2b774fea2b',
      overlay: false,
      searchProjectName: '',
      industryCategory: '',
      industry: '',
      fillingUser: '',
      industrys: '',
      status: '',
      searchParame: {
        page: 0,
        size: 20,
        formId: '76fa7297-55e4-4791-a239-897b24dad6a3',
        _from: 'grant',
      },
      uiInfo: {
        reserve: {
          color: '',
          text: '',
        },
        negotiation: {
          color: '',
          text: '',
        },
        signing: {
          color: '',
          text: '',
        },
        implementation: {
          color: '',
          text: '',
        },
      },
      nowType: '',
      formName: '信息列表',
      projectList: [], // 项目数据列表
      projectName: '',
      projectId: '',
      deleteMobel: false, // 删除确认框
      page: 0,
      nowPage: 1,
      pageNum: 0,
      delete_project_success: false, // 成功删除项目
      formNameColor: 'blue darken-4',
      projectTotal: 0,
      parentName: '',
      stageStatus: '',
      lightStatus: '',
      stageStatuss: [{ name: '待处理', code: '待处理' }, { name: '接收', code: '接收' }, { name: '拒绝', code: '拒绝' }, { name: '对接', code: '对接' }, { name: '中止', code: '中止' }, { name: '在谈', code: '在谈' }, { name: '签约', code: '签约' }, { name: '落地', code: '落地' }],
      lightStatuss: [{ name: '绿灯', code: '绿灯' }, { name: '黄灯', code: '黄灯' }, { name: '红灯', code: '红灯' }, { name: '灰灯', code: '灰灯' }],
      isXs: false,

    }),
    watch: {
      // 监听路由是否变化，一旦变化重新设置表单id和拉取项目列表
      $route: {
        handler (to, from) {

        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    async created () {
      this.industrys = await this.getdictionar('08be7c1a-f3c7-4ef3-aef6-a2f56eb82531')
      this.getProjectList()
    },
    methods: {
      async getdictionar (name) {
        const resp = await this.$axios.get(`/dictionaries/${name}`)
        return resp.data.data
      },
      async getAdvance (item) {
        console.log('item', item);
        const list = await this.getDetail(item.data.parentID)
        this.$refs.AdvanceReport.open(list)
      },
      async getDetail (id) {
        const resp = await this.$axios.get('/reports/' + id + '/detail')
        console.log('resp', resp);
        return resp.data.data
      },
      openPlan (item) {
        this.$refs.PlanReport.open(item)
      },
      timeColor (item) {
        if (item.data.projectLight === '绿灯') {
          return '#20c954'
        } else if (item.data.projectLight === '红灯') {
          return 'red'
        } else if (item.data.projectLight === '黄灯') {
          return '#FF9900'
        } else {
          return '#20c954'
        }
      },
      timeColor2 (item) {
        if (item.data.projectStatusLight === '绿灯') {
          return '#20c954'
        } else if (item.data.projectStatusLight === '红灯') {
          return 'red'
        } else if (item.data.projectStatusLight === '黄灯') {
          return '#FF9900'
        } else {
          return '#20c954'
        }
      },
      async getProjectList () { // 获取项目列表
        const searchParame = this.searchParame
        if (this.industry) {
          searchParame.industry = this.industry
        } else {
          delete searchParame.industry
        }
        if (this.fillingUser) {
          searchParame.fillingUser = this.fillingUser
        } else {
          delete searchParame.fillingUser
        }
        if (this.stageStatus) {
          searchParame.stageStatus = this.stageStatus
        } else {
          delete searchParame.stageStatus
        }
        if (this.lightStatus) {
          searchParame.lightStatus = this.lightStatus
        } else {
          delete searchParame.lightStatus
        }
        if (this.parentName) {
          searchParame.parentName = this.parentName
        } else {
          delete searchParame.parentName
        }
        const Timeout = setTimeout(() => { this.overlay = true }, 200)
        await this.$axios.get('/reports/query', {
          params: searchParame, // 搜索参数对象
        })
          .then((response) => {
            const resData = response.data.data
            this.projectList = resData.content
            this.projectList.map(async val => {
              const list = await this.getParentList(val)
              console.log('list', list);
              this.$set(val.data, 'projectStatusLight', list.projectStatusLight)
              this.$set(val.data, 'projectLight', list.projectLight)
              this.$set(val.data, 'parentName', list.name)
            })
            this.projectStatistics = resData.totalElements // 项目总数统计数
            this.pageNum = resData.totalPages
            this.projectTotal = resData.totalElements
            if (!resData.totalPages > 0) {
              this.$swal({
                title: '未搜索到数据!',
                icon: 'warning',
                showConfirmButton: false,
                timer: 1500,
              })
            }
            clearTimeout(Timeout)
            this.overlay = false
          })
          .catch((error) => {
            console.log(error)
            this.overlay = false
          })
      },
      async getParentList (item) {
        const resp = await this.$axios.get(`/reports/${item.data.parentID}/detail`)
        return resp.data.data.data
      },
      is_weixin () {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.indexOf('micromessenger') !== -1) {
          console.log('微信浏览器');
          return true;
        } else {
          console.log('不是微信浏览器');
          return false;
        }
      },
      exportTable () {
        if (this.is_weixin()) {
          this.$swal({
            title: '点击右上角选择在浏览器中打开!',
            icon: 'warning',
            showConfirmButton: false,
            timer: 1500,
          })
        } else {
          // delete this.searchParame.formId
          this.exportFile('reports/' + this.searchParame.formId + '/export', this.searchParame)
        }
      },
      // 导出文件
      async exportFile (url, params) {
        let data
        try {
          data = await this.$axios.get(url, {
            responseType: 'blob',
            params: params,
          })
        } catch (e) {
          console.log(e)
        }
        setTimeout(() => {
        }, 1000);
        if (!data || !data.data || data.data.length < 200) {
          this.$swal({
            title: '导出失败，请稍后再试！',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: '知道了',
          })
          return
        }
        file.downFile(data.data, '导出表格')
      },
      async dockint (item, name, content) {
        const answerTime = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        const searchParames = {
          abortReason: content,
          answerTime: answerTime,
          stageStatus: name,
        }
        // 判断是否对接/接收
        if (name === '接收') {
          searchParames.isDocking = '是'
          searchParames.receiveTime = answerTime
        } else if (name === '对接') {
          searchParames.isDocking = '是'
          searchParames.dockingTime = answerTime
        } else {
          searchParames.isDocking = '否'
        }
        const resp = await this.$axios.patch(`/reports/${item.id}`,
                                             searchParames,
        )
        console.log('resp', resp);
        if (resp.data.code !== 200) {
          return this.$swal({
            title: '未知错误!',
            icon: 'error',
          })
        }
        this.getProjectList()
        this.$swal({
          title: `已${name}`,
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        })
      },
      // 授权给组织机构
      async putGroup (reportId, groupIds) {
        const res = await this.$axios.post(`/reports/${reportId}/access`, { grantId: groupIds, type: 'role', permission: 'read,write' })
        if (res.data.code !== 200) {
          return this.$swal({
            title: '授权失败!',
            icon: 'error',
          })
        }
        console.log(res)
      },
      deleteMobelBtn (item, name) {
        this.$swal({
          title: name === '对接' ? '项目已对接' : `你确定${name}此项目吗？`,
          // text: '中止对接后将无法再次接受对接',
          input: 'textarea',
          animation: false,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          showLoaderOnConfirm: true,
          inputPlaceholder: name === '对接' ? `请填写${name}情况` : `请填写${name}理由`,
          preConfirm: (content) => {
            if (content === '') {
              this.$refs.audioBox.audioPlay('error')
              this.$swal.showValidationMessage(
                name === '对接' ? `${name}情况不能为空！` : `${name}理由不能为空！`,
              )
            } else {
              this.dockint(item, name, content)
            }
          },
        })
      },
      see (index) { // 查看项目
        this.$router.push({
          name: 'ReportDetails',
          params: {
            formId: this.projectList[index].data.parentFormId,
            id: this.projectList[index].data.parentID,
          },
          query: {
            dynamicId: this.projectList[index].id,
          },
        })
      },
      see2 (index) {
        this.$router.push({
          name: 'DynamicDetails',
          params: {
            formId: this.projectList[index].data.parentFormId,
            id: this.projectList[index].data.parentID,
          },
          query: {
            park: this.projectList[index].data.park,
            dynamicId: this.projectList[index].id,
            parkId: this.projectList[index].data.parkId,
            parkIds: this.projectList[index].data.parkIds,
          },
        })
      },
      pageChange (value) { // 换页按钮
        this.nowPage = value
        this.searchParame.page = value - 1
        this.getProjectList()
      },
    },
  }
</script>
<style scoped>
  .projectNameLink {
    cursor: pointer;
  }
</style>
